import React from "react";
import Background from "./paesaggio.jpg";
import "./login.css";

import { authProvider } from "authProvider";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { Grid, Button, TextField, Box } from "@pankod/refine-mui";
import { toast } from "react-toastify";

import LogosGrid from "components/layout/LogosGrid";

export default function Login() {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authProvider.login({
        username: e.target.username.value,
        password: e.target.password.value,
      });
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.log({ error });
      toast.error("Credenziali non valide");
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="center"
          alignItems="center"
          className="login-bg"
          style={{
            height: "100%",
            width: "100%",
            backgroundImage: `url(${Background})`,
          }}
        >
          <div></div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="center"
          alignItems="center"
          p={{ xs: 4, md: 8 }}
          sm
        >
          <Grid container spacing={3}>
            <div style={{ maxWidth: "500px", margin: "0 auto" }}>
              <Grid item xs={12} container justifyContent="left">
                <img
                  src="/logo.png"
                  alt="Logo"
                  style={{ height: 80, objectFit: "contain" }}
                />
              </Grid>
              <Grid item container justifyContent="left">
                <h1 level={3}>Benvenuto</h1>
              </Grid>
              <Grid item container justifyContent="right">
                <div style={{ marginBottom: "2px" }}>
                  <span>Sei un produttore?&nbsp;</span>
                  <a
                    style={{
                      float: "right",
                      fontSize: "16px",
                      color: "#555",
                      paddingLeft: "4px;",
                    }}
                    href="/registration-requests/seller"
                  >
                    Richiedi accesso come produttore
                  </a>
                </div>
                <div style={{ marginBottom: "36px" }}>
                  <span>Sei un acquirente?&nbsp;</span>

                  <a
                    style={{
                      float: "right",
                      fontSize: "16px",
                      color: "#555",
                      paddingLeft: "4px;",
                    }}
                    href="/registration-requests/buyer"
                  >
                    Richiedi accesso come acquirente
                  </a>
                </div>
              </Grid>
            </div>
          </Grid>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxWidth: "500px",
            }}
            autoComplete="off"
          >
            <TextField
              name="username"
              label="Email"
              rules={[{ required: true }]}
              placeholder="Email"
              required
              autoFocus
              sx={{ mb: 2 }}
            ></TextField>
            <TextField
              name="password"
              label="Password"
              rules={[{ required: true }]}
              style={{ marginBottom: "36px" }}
              type="password"
              placeholder="●●●●●●●●"
              required
            ></TextField>
            <div style={{ marginBottom: "12px" }}>
              <a
                style={{
                  float: "right",
                  fontSize: "16px",
                  color: "#555",
                }}
                href="/forgot-password"
              >
                Hai dimenticato la password?
              </a>
            </div>

            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
              sx={{ background: "#a4421d", color: "white" }}
            >
              Accedi
            </Button>
          </Box>
          <LogosGrid height="100%" sx={{ mt: 4 }} />
        </Grid>
      </Grid>
    </Grid>
  );
}
