import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@pankod/refine-mui";
import { useState } from "react";

export default function CompFields({
  register,
  control,
  errors,
  record,
  getValues,
}) {
  const [isSeller, setIsSeller] = useState(
    record?.is_seller || getValues("is_seller") || false
  );

  console.log({ isSeller });
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          {...register("name", { required: "Nome obbligatorio" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.name}
          helperText={errors.name?.message}
          required
          fullWidth
          label="Nome"
        />
        <br />
        <br />
        <FormControl>
          <FormLabel id="user-category-group">
            <Typography
              variant="body"
              sx={{ fontWeight: "bold", marginTop: "20px" }}
            >
              CATEGORIA
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="user-category-group"
            {...register("is_seller", {})}
            control={control}
            value={isSeller}
            onChange={(e) => {
              console.log({ isSeller, val: e.target.value });
              setIsSeller(e.target.value === "true");
            }}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="PRODUTTORE"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="ACQUIRENTE"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}
