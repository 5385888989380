import { Create, Typography } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

import Fields from "./Fields";
import { useList, useOne } from "@pankod/refine-core";

export default function CompCreate() {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const adv = useOne({
    resource: "advertisements",
    id: Number(localStorage.getItem("ad")),
    metaData: {
      populate: "*",
    },
  });

  const { data: products, isFetching } = useList({
    resource: "products",
  });

  if (isFetching) return null;

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={<Typography variant="h5">Crea Offerta</Typography>}
    >
      <Fields
        register={register}
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        adv={adv?.data?.data}
        productsData={products.data}
      />
    </Create>
  );
}
