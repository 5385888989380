import { useMemo } from "react";
import format from "date-fns/format";
import {
  Typography,
  useDataGrid,
  DataGrid,
  List,
  Stack,
  Button,
  itIT,
} from "@pankod/refine-mui";
import { Link } from "@pankod/refine-react-router-v6";
import StatusChip from "components/StatusChip";
import VisibilityIcon from "@mui/icons-material/Visibility";

const DeCoButton = ({ recordItemId }: { recordItemId: number }) => {
  return (
    <Link
      to={`/vedi-richieste-deco/${recordItemId}`}
      replace={false}
      style={{ textDecoration: "none" }}
    >
      <Button sx={{ minWidth: 0 }}>
        <VisibilityIcon sx={{ color: "gray", cursor: "pointer" }} />
      </Button>
    </Link>
  );
};

function DecoRequestList() {
  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID" },
      {
        field: "seller",
        headerName: "Produttore",
        flex: 1,
        minWidth: 100,
        renderCell: function render(p: any) {
          // console.log(lastRequest);
          return <span>{p.row.product.owner.username}</span>;
        },
      },
      {
        field: "product",
        headerName: "Prodotto",
        flex: 1,
        minWidth: 100,
        renderCell: function render(p: any) {
          // console.log(lastRequest);
          return <span>{p.row.product.name}</span>;
        },
      },
      {
        field: "category",
        headerName: "Categoria",
        flex: 1,
        minWidth: 100,
        renderCell: function render(p: any) {
          // console.log(lastRequest);
          return <span>{p.row.product?.product_category?.name || "N/A"}</span>;
        },
      },
      {
        field: "request_date",
        headerName: "Data richiesta",
        flex: 1,
        minWidth: 100,
        renderCell: function render(p: any) {
          if (!p.row.createdAt) return null;
          return <span>{format(new Date(p.row.createdAt), "dd/MM/yyyy")}</span>;
        },
      },
      {
        field: "status",
        headerName: "Approvazione De.Co",
        flex: 1,
        minWidth: 100,
        renderCell: function render(p: any) {
          return (
            <StatusChip
              label={p.row.status || "nessuna"}
              status={p.row.status}
            />
          );
        },
      },
      {
        headerName: "AZIONE",
        field: "actions",
        minWidth: 50,
        sortable: false,
        renderCell: function render(params: any) {
          return (
            <Stack direction="row" spacing={1}>
              <DeCoButton recordItemId={Number(params.row.id)} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps } = useDataGrid({
    resource: "deco-requests",
    metaData: {
      populate: { product: { populate: "*" } },
    },
  });

  return (
    <List
      resource="deco-requests"
      headerProps={{
        title: <Typography variant="h5">Richieste De.Co.</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />
    </List>
  );
}

export default DecoRequestList;
