import { useMemo } from "react";
import {
  Typography,
  useDataGrid,
  DataGrid,
  List,
  itIT,
} from "@pankod/refine-mui";

function ProductCategoryList() {
  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID" },
      {
        field: "name",
        headerName: "Nome del Comune",
        flex: 1,
        minWidth: 300,
      },
    ],
    []
  );

  const { dataGridProps } = useDataGrid();

  return (
    <List
      resource="municipalities"
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Comuni dell'Amerino</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />
    </List>
  );
}

export default ProductCategoryList;
