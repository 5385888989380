import {
  Checkbox,
  Create,
  Grid,
  Typography,
  SaveButton,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { Link } from "@pankod/refine-react-router-v6";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Fields from "./Fields";
import axiosInstance from "utils/axios";

export default function UserRegistration() {
  const {
    refineCore: { formLoading, onFinish },
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
  } = useForm();

  const params = useParams();

  const [isTosSelected, setIsTosSelected] = useState(false);

  const title = `RICHIESTA ACCESSO ${
    params.type === "seller" ? "PRODUTTORE" : "ACQUIRENTE"
  }`;

  return (
    <>
      <Grid container style={{ backgroundColor: "#e4e4e4" }}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="flex-start"
          style={{
            margin: "40px auto",
            height: "100vh",
            maxWidth: "800px",
          }}
        >
          <Create
            isLoading={formLoading}
            onErrors={(_, error) => {
              console.log(error);
              if (error?.response?.data?.message) {
                error.response.data.message.forEach((message) => {
                  toast.error(message);
                });
              }
            }}
            footerButtons={
              <Grid container justifyContent="center">
                <SaveButton
                  onClick={async () => {
                    try {
                      let { data: emailExists } = await axiosInstance.post(
                        `/api/check-email`,
                        {
                          email: getValues("email"),
                        }
                      );
                      await handleSubmit(onFinish)();
                    } catch (error) {
                      toast.error("Email già registrata");
                    }
                  }}
                  disabled={!isTosSelected}
                >
                  INVIA
                </SaveButton>
              </Grid>
            }
            goBack={false}
            title={
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                direction="column"
              >
                <Link to={`/`} style={{ textDecoration: "none" }}>
                  <img
                    src="/logo.png"
                    alt="Logo"
                    style={{ height: 80, objectFit: "contain" }}
                  />
                </Link>

                <Typography mt={6} mb={2} variant="h4">
                  {title}
                </Typography>
              </Grid>
            }
          >
            <Fields
              register={register}
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              isSeller={params.type === "seller"}
            />
            <Grid
              container
              spacing={2}
              style={{ padding: "30px" }}
              justifyContent="start"
              gap={1}
              alignItems="center"
            >
              <Checkbox
                checked={isTosSelected}
                onChange={() => setIsTosSelected((sel) => !sel)}
              />
              <Typography variant="body2">accetto le condizioni</Typography>
            </Grid>
          </Create>
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  );
}
