import {
  Button,
  Edit,
  Grid,
  Typography,
  Box,
  Divider,
} from "@pankod/refine-mui";
// import axios from "utils/axios";
import format from "date-fns/format";
import { useForm } from "@pankod/refine-react-hook-form";
import { useOne, useNavigation } from "@pankod/refine-core";
import { useParams } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";

import Fields from "./Fields";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function CompEdit() {
  const {
    refineCore: { formLoading, onFinish },
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const [loaded, setLoaded] = useState(false);

  const { push } = useNavigation();

  const params = useParams();
  const record = useOne({
    resource: "products",
    id: params.id,
    metaData: {
      populate: "*",
    },
  });

  useEffect(() => {
    if (record?.data?.data && !loaded) {
      setLoaded(true);
      // set prices
    }
  }, [record, loaded]);

  const submitHandler = (values) => {
    onFinish({
      ...values,
    });
  };

  const lastDeCoRequest = record?.data?.data.deco_requests.sort(
    (a, b) => a.id - b.id
  );
  const lastRequest =
    lastDeCoRequest && lastDeCoRequest.length > 0
      ? lastDeCoRequest[(lastDeCoRequest || []).length - 1]
      : undefined;

  const createDecoRequest = async () => {
    try {
      localStorage.setItem("prod", params.id);
      setTimeout(() => {
        push(`/deco-requests/create`);
      }, 100);
    } catch (error) {
      toast.error("Richiesta non inviata");
    }
  };

  return (
    <Edit
      title={
        <Typography variant="h4">{`Modifica ${
          record?.data?.data.name || ""
        }`}</Typography>
      }
      headerButtonProps={{
        sx: {
          display: "none",
        },
      }}
      isLoading={formLoading}
      saveButtonProps={{
        onClick: handleSubmit(submitHandler),
      }}
    >
      <Fields
        register={register}
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        record={record?.data?.data}
      />

      <Divider sx={{ my: 1 }} />

      <Grid
        container
        spacing={2}
        style={{ padding: "30px", marginTop: "30px" }}
        justifyContent="start"
        gap={1}
        alignItems="center"
      >
        {!lastRequest || lastRequest.status === "rejected" ? (
          <Button
            startIcon={<VerifiedIcon />}
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => createDecoRequest()}
          >
            Richiedi De.Co
          </Button>
        ) : null}
        {lastRequest && lastRequest.status === "pending" && (
          <Box>
            RICHIESTA De.Co. EFFETTUATA IL{" "}
            {format(new Date(lastRequest.createdAt), "dd/MM/yyyy")} alle ore
            &nbsp;
            {format(new Date(lastRequest.createdAt), "hh:mm")}
          </Box>
        )}
        {lastRequest && lastRequest.status === "accepted" && (
          <Box>
            RICHIESTA De.Co. ACCETTATA IL{" "}
            {format(new Date(lastRequest.updatedAt), "dd/MM/yyyy")} alle ore
            &nbsp;
            {format(new Date(lastRequest.updatedAt), "hh:mm")}
          </Box>
        )}
      </Grid>
    </Edit>
  );
}
