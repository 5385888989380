import { useOne } from "@pankod/refine-core";
import { Button, DataGrid, Stack, itIT } from "@pankod/refine-mui";
import { Link, useParams } from "@pankod/refine-react-router-v6";
import React, { useMemo } from "react";

function ListOffers() {
  const params = useParams();
  const record = useOne({
    resource: "advertisements",
    id: params.id,
    metaData: {
      populate: "*",
    },
  });

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID" },
      {
        field: "seller",
        headerName: "Venditore",
        flex: 1,
        minWidth: 150,
        renderCell: function render(p) {
          return <div>{p.row.owner.business_name}</div>;
        },
      },
      {
        field: "seller_reply",
        headerName: "Risposta",
        flex: 1,
        minWidth: 150,
      },

      {
        headerName: "Azioni",
        field: "actions",
        minWidth: 50,
        renderCell: function render(p) {
          return (
            <Stack direction="row" spacing={1}>
              <Link to={`/offer/${p.row.id}`}>
                <Button>VEDI</Button>
              </Link>
            </Stack>
          );
        },
      },
    ],
    []
  );

  if (!record?.data?.data) return null;

  return (
    <div>
      OFFERTE PER {record?.data?.data?.title}
      <DataGrid
        rows={record.data.data.offers}
        columns={columns}
        autoHeight
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />{" "}
    </div>
  );
}

export default ListOffers;
