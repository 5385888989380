import {
  Autocomplete,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Box,
} from "@pankod/refine-mui";
import { Controller } from "@pankod/refine-react-hook-form";
import MultiSelect from "components/MultiSelect";

export default function CompFields({
  fieldVariants,
  label,
  name,
  variant,
  required,
  defaultValue,
  type,
  options,
  accessor,
  lines,
  xs,
  md,
  lg,
  register,
  control,
  errors,
  getValues,
  setValue,
  style,
  disabled,
  min,
  placeholder,
  viewMode,
}) {
  if (type === "title") {
    return (
      <Grid item xs={12}>
        <Typography variant={variant} gutterBottom component="div">
          {label}
        </Typography>
        <Divider sx={{ mb: 2 }} />
      </Grid>
    );
  }
  if (type === "space")
    return <Grid item xs={12} sx={{ height: xs || 1 }}></Grid>;
  if (type === "divider")
    return (
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={xs || 12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
      </Grid>
    );
  if (type === "date") {
    if (viewMode)
      return (
        <Grid item xs={xs || 12} md={md || 12} lg={lg || 12}>
          <Box sx={{ borderBottom: 1 }}>
            <Typography variant="h5" gutterBottom component="div">
              {label}
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              {getValues(name)}
            </Typography>
          </Box>
        </Grid>
      );
    return (
      <Grid item xs={xs || 12} md={md || 12} lg={lg || 12}>
        <TextField
          {...register(name, {
            required: label + " Obbligatorio",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { min } }}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          required={required}
          variant={fieldVariants || "outlined"}
          defaultValue={defaultValue}
          fullWidth
          label={label}
          type="date"
          style={style || {}}
        />
      </Grid>
    );
  }

  if (type === "text" || type === "number") {
    if (viewMode)
      return (
        <Grid item xs={xs || 12} md={md || 12} lg={lg || md || 12}>
          <Box sx={{ borderBottom: 1 }}>
            <Typography variant="h5" gutterBottom component="div">
              {label}
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              {getValues(name)}
            </Typography>
          </Box>
        </Grid>
      );
    return (
      <Grid item xs={xs || 12} md={md || 12} lg={lg || md || 12}>
        <TextField
          {...register(name, {
            required: label + " Obbligatorio",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          required={required}
          variant={fieldVariants || "outlined"}
          defaultValue={defaultValue}
          fullWidth
          label={label}
          type={type}
          multiline={lines && lines > 1}
          rows={lines}
          style={style || {}}
          placeholder={placeholder}
        />
      </Grid>
    );
  }
  if (type === "select") {
    if (viewMode)
      return (
        <Grid item xs={xs || 12} md={md || 12}>
          <Box sx={{ borderBottom: 1 }}>
            <Typography variant="h5" gutterBottom component="div">
              {label}
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              {getValues(name)}
            </Typography>
          </Box>
        </Grid>
      );
    return (
      <Grid item xs={xs || 12} md={md || 12}>
        <TextField
          {...register(name, {
            required: label + " Obbligatorio",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          required={required}
          variant={fieldVariants || "outlined"}
          defaultValue={defaultValue}
          fullWidth
          label={label}
          select
          disabled={disabled || false}
          value={getValues(name) || ""}
          onChange={(e) => {
            console.log(e.target.value);
            setValue(name, e.target.value, { shouldValidate: true });
          }}
          style={style || {}}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  }
  if (type === "autocomplete") {
    if (viewMode)
      return (
        <Grid item xs={xs || 12} md={md || 12}>
          <Box sx={{ borderBottom: 1 }}>
            <Typography variant="h5" gutterBottom component="div">
              {label}
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              {getValues(name)?.[accessor]}
            </Typography>
          </Box>
        </Grid>
      );
    return (
      <Grid item xs={xs || 12} md={md || 12}>
        <Controller
          control={control}
          name={name}
          rules={{ required: label + " Obbligatorio" }}
          render={({ field }) => (
            <Autocomplete
              {...options}
              {...field}
              value={field.value}
              defaultValue={defaultValue}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => {
                return item[accessor] ? item[accessor] : "";
              }}
              isOptionEqualToValue={(option, value) => {
                return value === undefined || option.id === value.id;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  variant={fieldVariants || "outlined"}
                  error={!!errors[name]}
                  helperText={errors[name]?.message}
                  required
                />
              )}
            />
          )}
        />
      </Grid>
    );
  }
  if (type === "multi-select") {
    if (viewMode)
      return (
        <Grid item xs={xs || 12} md={md || 12}>
          <Box sx={{ borderBottom: 1 }}>
            <Typography variant="h5" gutterBottom component="div">
              {label}
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              {getValues(name)
                ?.map((item) => item[accessor])
                .join(", ")}
            </Typography>
          </Box>
        </Grid>
      );
    return (
      <Grid item xs={xs || 12} md={md || 12}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <MultiSelect
              name={label}
              variant={fieldVariants || "standard"}
              options={options}
              value={field.value}
              defaultValue={defaultValue}
              onChange={(val) => {
                field.onChange(val);
                setValue(name, val, { shouldValidate: true });
              }}
            />
          )}
        />
      </Grid>
    );
  }
  return <></>;
}
