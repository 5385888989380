import { Create, Typography } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

import Fields from "./Fields";

export default function CompCreate() {
  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  // const submitHandler = (values) => {
  //   let fieldValue = [];
  //   values.prices.forEach((val) => {
  //     fieldValue.push({ price: val.price });
  //   });

  //   onFinish({
  //     ...values,
  //     skills: fieldValue,
  //   });
  // };

  return (
    <Create
      title={<Typography variant="h4">INSERIMENTO PRODOTTO</Typography>}
      headerButtonProps={{
        sx: {
          display: "none",
        },
      }}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
    >
      <Fields
        register={register}
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
      />
    </Create>
  );
}
