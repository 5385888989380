import { PriceCheck } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import format from "date-fns/format";
import {
  Button,
  DataGrid,
  DeleteButton,
  EditButton,
  List,
  Stack,
  Typography,
  itIT,
  useDataGrid,
} from "@pankod/refine-mui";
import { Link } from "@pankod/refine-react-router-v6";
import StatusChip from "components/StatusChip";
import { useCallback, useMemo } from "react";
import { useNavigation } from "@pankod/refine-core";
import { request } from "http";

// type DeCoRequest = {
//   id: number;
//   status: string;
//   requestedAt: Date;
// };

function getRequestDate(params: any) {
  if (!params.row["deco-requests"] || params.row["deco-requests"].length === 0)
    return "";
  return `${params.row["deco-requests"][0].requestedAt || ""} `;
}

const DeCoButton = (recordItemId: any) => (
  <Link
    to={`/request-deco/${recordItemId}`}
    replace={false}
    style={{ textDecoration: "none" }}
  >
    <Button sx={{ minWidth: 0 }}>
      <PriceCheck fontSize="small" />
    </Button>
  </Link>
);

function ProductList() {
  const { push } = useNavigation();

  const viewDecoRequest = useCallback(
    (id: number) => {
      push(`/vedi-richieste-deco/${id}`);
    },
    [push]
  );

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID" },
      {
        field: "name",
        headerName: "PRODOTTO",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "product-category",
        headerName: "CATEGORIA",
        flex: 1,
        minWidth: 150,
        renderCell: function render(p: any) {
          return <strong>{p.row?.product_category?.name || "N/A"}</strong>;
        },
        sortable: false,
      },
      {
        field: "deco-requests-status",
        headerName: "APPROVAZIONE De.Co",
        flex: 1,
        minWidth: 200,
        renderCell: function render(p: any) {
          const requests = p.row.deco_requests.sort(
            (a: any, b: any) => a.id - b.id
          );
          const lastRequest =
            requests[(requests || []).length - 1] || undefined;
          // console.log(lastRequest);
          // if (!lastRequest) return null;
          return (
            <Stack direction="row" gap={1} alignItems="center">
              <StatusChip
                label={lastRequest ? requests[0]?.status : "nessuna"}
                status={lastRequest?.status}
              />
              {lastRequest && (
                <VisibilityIcon
                  sx={{ color: "gray", cursor: "pointer" }}
                  onClick={() => viewDecoRequest(lastRequest.id)}
                />
              )}
            </Stack>
          );
        },
        sortable: false,
      },
      {
        field: "deco-requests-date",
        headerName: "DATA RICHIESTA",
        flex: 1,
        minWidth: 200,
        valueGetter: getRequestDate,
        renderCell: function render(p: any) {
          const requests = p.row.deco_requests.sort(
            (a: any, b: any) => a.id - b.id
          );
          const lastRequest = requests[0] || undefined;
          // console.log(lastRequest);
          return (
            <span>
              {lastRequest
                ? format(new Date(lastRequest.createdAt), "dd/MM/yyyy")
                : ""}
            </span>
          );
        },
        sortable: false,
      },
      {
        headerName: "AZIONE",
        field: "actions",
        minWidth: 50,
        sortable: false,
        renderCell: function render(params: any) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
              <DeCoButton recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    [viewDecoRequest]
  );

  const { dataGridProps } = useDataGrid({
    resource: "products",
    metaData: {
      populate: { product_category: { fields: ["name"] }, deco_requests: true },
    },
  });

  return (
    <List
      resource="products"
      createButtonProps={{
        size: "small",
        title: <Typography variant="h5">CREA PRODOTTO</Typography>,
      }}
      headerProps={{
        title: <Typography variant="h5">LISTA PRODOTTI</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />
    </List>
  );
}

export default ProductList;
