import {
  DataGrid,
  List,
  Stack,
  Typography,
  useDataGrid,
  itIT,
} from "@pankod/refine-mui";
import { Link } from "@pankod/refine-react-router-v6";
import { useEffect, useMemo } from "react";

function SellerList() {
  const columns = useMemo(
    () => [
      {
        field: "municipality",
        headerName: "Comune",
        flex: 1,
        maxWidth: 300,
        renderCell: function render(p) {
          return <span>{p?.formattedValue?.name || "N/A"}</span>;
        },
      },
      {
        field: "username",
        headerName: "Nome Utente",
        flex: 1,
        maxWidth: 300,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        maxWidth: 300,
      },
      {
        field: "address",
        headerName: "Indirizzo",
        flex: 1,
        maxWidth: 300,
      },
      {
        field: "zip_code",
        headerName: "CAP",
        flex: 1,
        maxWidth: 300,
      },
      {
        field: "vat",
        headerName: "Partita IVA",
        flex: 1,
        maxWidth: 300,
      },
      {
        field: "phone",
        headerName: "Telefono",
        flex: 1,
        maxWidth: 300,
      },
      {
        field: "user_category",
        headerName: "Categoria Utente",
        flex: 1,
        maxWidth: 300,
        renderCell: function render(p) {
          return <span>{p?.formattedValue?.name || "N/A"}</span>;
        },
      },
      {
        field: "role",
        headerName: "Ruolo",
        flex: 1,
        maxWidth: 300,
        renderCell: function render(p) {
          return <span>{p?.formattedValue?.name || "N/A"}</span>;
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        sortable: false,
        renderCell: function render(p) {
          return (
            <Stack direction="row" spacing={1}>
              <Link to={`/produttori/${p.row.id}`}>VEDI</Link>
              {/* <DeleteButton hideText recordItemId={params.row.id} /> */}
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, setFilters } = useDataGrid({
    resource: "users",
  });

  useEffect(() => {
    setFilters([
      {
        field: "is_seller",
        value: true,
        operator: "eq",
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List
      resource="users"
      createButtonProps={{ size: "small" }}
      canCreate={false}
      headerProps={{
        title: <Typography variant="h5">ELENCO UTENTI</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />
    </List>
  );
}

export default SellerList;
