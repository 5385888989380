import React from "react";
import { useOne } from "@pankod/refine-core";
import { Button, Grid, Stack, Typography } from "@pankod/refine-mui";
import { Link, useParams } from "@pankod/refine-react-router-v6";
import Back from "@mui/icons-material/ChevronLeftSharp";

function ViewAdvertisement() {
  const params = useParams();
  const record = useOne({
    resource: "advertisements",
    id: params.id,
    metaData: {
      populate: { municipality: { fields: ["name"] }, owner: true },
    },
  });

  console.log(record.data);

  if (!record.data) return null;

  const adv = record.data.data;

  return (
    <Grid item xs={12} ml={4}>
      <Typography variant="body2">
        <Link to="/advertisements">
          <Button startIcon={<Back />}>Indietro</Button>
        </Link>
      </Typography>
      <Grid container spacing={2} style={{ padding: "30px" }}>
        <Stack direction="column">
          <Typography variant="h3">{adv.title}</Typography>
          <hr />
          <Typography variant="h5">
            Richiesta da: {adv?.owner?.business_name}{" "}
          </Typography>
          <Typography variant="body1">
            <h3>Descrizione:</h3>

            <span
              dangerouslySetInnerHTML={{
                __html: adv.description.replace(/\n/g, "<br />"),
              }}
            />
          </Typography>
        </Stack>
      </Grid>
      <Grid container spacing={2} style={{ padding: "30px" }}>
        <Typography variant="body1" ml={1} mt={4}>
          <Stack direction="row" gap={8}>
            <Stack direction="column" gap={1}>
              <div>
                <strong>Data pubblicazione:</strong>
              </div>
              <div>{adv.publication_date}</div>
            </Stack>
            <Stack direction="column" gap={1}>
              <div>
                <strong>Data scadenza:</strong>
              </div>
              <div>{adv.expiry_date}</div>
            </Stack>
            <Stack direction="column" gap={1}>
              <div>
                <strong>Comune:</strong>
              </div>
              <div>{adv.municipality?.name || "MANCA DATO"}</div>
            </Stack>
          </Stack>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ViewAdvertisement;
