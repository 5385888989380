import { useState, useEffect } from "react";

import { Create, Typography } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { useNavigation, useOne } from "@pankod/refine-core";

import Fields from "./Fields";

import axios from "utils/axios";

export default function CompCreate() {
  const { push } = useNavigation();
  const {
    refineCore: { formLoading, onFinish },
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    redirect: false,
  });

  // const [isTos1Selected, setIsTos1Selected] = useState(false);
  // const [isTos2Selected, setIsTos2Selected] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axios.get("/api/users/me");
      setUser(data);
    };
    fetchUser();
  }, []);

  const submitHandler = (values) => {
    onFinish({
      ...values,
      accepted_tos_1: true,
      accepted_tos_2: true,
    });

    push("/products");
  };

  return (
    <Create
      title={<Typography variant="h4">MODULO RICHIESTA DE.CO.</Typography>}
      headerButtonProps={{
        sx: {
          display: "none",
        },
      }}
      isLoading={formLoading}
      // saveButtonProps={saveButtonProps}
      saveButtonProps={{
        onClick: handleSubmit(submitHandler),
        // disabled: !isTos1Selected || !isTos2Selected,
      }}
    >
      <Fields
        user={user}
        register={register}
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        record={user?.registration_request}
      />
      {/* <Stack direction="column" gap={1} paddingLeft={2}>
        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={isTos1Selected}
            onChange={() => setIsTos1Selected((sel) => !sel)}
          />
          <Typography variant="body2">Accetto X</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={isTos2Selected}
            onChange={() => setIsTos2Selected((sel) => !sel)}
          />
          <Typography variant="body2">Dichiaro Y</Typography>
        </Stack>
      </Stack> */}
    </Create>
  );
}
