import { useState, useEffect } from "react";

import {
  Grid,
  Card,
  TextField,
  Button,
  Stack,
  Divider,
  Typography,
} from "@pankod/refine-mui";

import { toast } from "react-toastify";

import axios from "utils/axios";
import { Link } from "@pankod/refine-react-router-v6";

export default function Settings() {
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axios.get("/api/users/me");
      setUser(data);
    };
    fetchUser();
  }, []);

  return (
    <Grid container spacing={5} justifyContent="space-evenly">
      <Grid item md={5} xs={11} sx={{ mt: -7, mb: 3 }}>
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            p={3}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12} container justifyContent="center">
              {user && (
                <h1
                  style={{ paddingTop: 10 }}
                >{`Benvenuto ${user.username}`}</h1>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        {user && user.role && user.role.name !== "Municipality" && (
          <Link to={`/seller/edit/${user.id}`}>
            <Button variant="contained" color="success" size="large">
              VISUALIZZA DATI
            </Button>
          </Link>
        )}
      </Grid>

      <Grid item xs={12} container justifyContent="space-evenly">
        {/* ACCOUNT --------------------------------------------------------------------------- */}
        <Grid item md={5} xs={11} mb={2}>
          <Card sx={{ borderRadius: 5, height: "100%" }}>
            <Grid
              item
              xs={12}
              container
              alignItems="space-between"
              justifyContent="center"
              p={3}
              sx={{ height: 300 }}
            >
              <h1 style={{ height: 48 }}>Dati utente</h1>
              <Grid
                item
                xs={12}
                sx={{ padding: 1, width: "100%", height: "100%" }}
                container
                rowSpacing={2}
              >
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ pointerEvents: "none" }}
                    fullWidth
                    label="Nome"
                    value={user?.username}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ pointerEvents: "none" }}
                    fullWidth
                    label="Email"
                    value={user?.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2 }} fullWidth>
                    Categoria Utente: {user?.user_category?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2 }} fullWidth>
                    Comune: {user?.municipality?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {/* PASSWORD --------------------------------------------------------------------- */}
        <Grid item md={5} xs={11} mb={2}>
          <Card sx={{ borderRadius: 5, height: "100%" }}>
            <Grid
              item
              xs={12}
              container
              alignItems="space-between"
              justifyContent="center"
              p={3}
              sx={{ height: "100%" }}
            >
              <h1>Modifica Password</h1>
              <Grid item xs={12} sx={{ padding: 1, width: "100%" }}>
                <Stack
                  direction="column"
                  spacing={2}
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    label="Password attuale"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                  />
                  <Divider
                    sx={{
                      width: "100%",
                      my: 1,
                    }}
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    label="Nuova password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    variant="outlined"
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    label="Conferma Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      if (newPassword === confirmPassword) {
                        try {
                          let res = await axios.post(
                            "/api/auth/change-password",
                            {
                              currentPassword: password,
                              password: newPassword,
                              passwordConfirmation: confirmPassword,
                            }
                          );
                          console.log(res.data);
                          toast.success("Password aggiornata");
                        } catch (error) {
                          console.log(error);
                          toast.error("Si è verificato un errore");
                        }
                        setPassword("");
                        setNewPassword("");
                        setConfirmPassword("");
                      }
                    }}
                  >
                    Aggiorna Password
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="space-evenly" mb={2}></Grid>
    </Grid>
  );
}
