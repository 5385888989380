import { useCallback, useMemo } from "react";
import {
  Typography,
  useDataGrid,
  DataGrid,
  List,
  Button,
  IconButton,
  itIT,
  EditButton,
  DeleteButton,
  Stack,
} from "@pankod/refine-mui";
import ViewIcon from "@mui/icons-material/Visibility";

import isAfter from "date-fns/isAfter";
import { useGetIdentity, useNavigation } from "@pankod/refine-core";
import { Link } from "@pankod/refine-react-router-v6";

function AdvertisementList() {
  // const [loaded, setLoaded] = useState(false)
  const { push } = useNavigation();
  const { data: user } = useGetIdentity();

  const userCanEdit = user?.role === "authenticated" || user?.role === "buyer";

  const viewOffer = useCallback(() => {
    push(`/offers/create`);
  }, [push]);

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", width: 40 },
      {
        field: "title",
        headerName: "TITOLO",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "description",
        headerName: "DESCRIZIONE",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "publication_date",
        headerName: "PUBBLICAZIONE",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "expiry_date",
        headerName: "SCADENZA",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "offers",
        headerName: "RISPOSTE",
        flex: 1,
        minWidth: 100,
        renderCell: function render(p) {
          return (
            <strong>
              {p.row.offers.length}
              <Link to={`/adv-offers/${p.row.id}`}>&nbsp;VEDI</Link>
            </strong>
          );
        },
      },

      {
        field: "status",
        headerName: "STATO",
        flex: 1,
        minWidth: 80,
        sortable: false,
        renderCell: function render(p) {
          let statusTitle = "APERTO";
          const isClosed = isAfter(Date.now(), new Date(p.row.expiry_date));
          const hasOffers = p.row.offers?.length > 0;
          const isAccepted = p.row.offers?.find((o) => o.status === "accepted");
          const myOffer = p.row.offers?.find((o) => o.owner?.id === user.id);
          // console.log({ isAccepted });
          if (isAccepted) statusTitle = "ACQUISTATO";
          if (!isAccepted) {
            if (isClosed) {
              if (!hasOffers || !myOffer) {
                statusTitle = "SCADUTO";
              }
              if (myOffer && myOffer.status !== "accepted")
                statusTitle = "SCADUTO";
            }
            if (!isClosed && hasOffers) statusTitle = "IN TRATTATIVA";
          }

          return <strong>{statusTitle}</strong>;
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 200,
        sortable: false,
        renderCell: function render(params) {
          const hasOffers = params.row.offers?.length > 0;

          const isClosed = isAfter(
            Date.now(),
            new Date(params.row.expiry_date)
          );
          const isAccepted = params.row.offers.find(
            (o) => o.status === "accepted"
          );
          if (!user?.id) return null;
          const myOffer = params.row.offers.find(
            (o) => o.owner?.id === user.id
          );

          if (userCanEdit) {
            if (!hasOffers && !isClosed)
              return (
                <Stack direction="row" spacing={1}>
                  <Link to={`/view-advertisement/${params.row.id}`}>
                    <IconButton sx={{ borderRadius: 1 }}>
                      <ViewIcon
                        sx={{ color: "primary.main", width: 20, height: 20 }}
                      />
                    </IconButton>
                  </Link>
                  <EditButton hideText recordItemId={params.row.id} />
                  <DeleteButton hideText recordItemId={params.row.id} />
                </Stack>
              );

            return (
              <Stack direction="row" spacing={1}>
                <Link to={`/view-advertisement/${params.row.id}`}>
                  <IconButton sx={{ borderRadius: 1 }}>
                    <ViewIcon
                      sx={{ color: "primary.main", width: 20, height: 20 }}
                    />
                  </IconButton>
                </Link>
              </Stack>
            );
          }

          if (isAccepted || isClosed)
            return (
              <Stack direction="row" spacing={1}>
                <Link to={`/view-advertisement/${params.row.id}`}>
                  <Button variant="outlined">VEDI</Button>
                </Link>
              </Stack>
            );

          if (myOffer)
            return (
              <Stack direction="row" spacing={1}>
                <Link to={`/offer/${params.row.offers[0].id}`}>
                  <Button variant="outlined">OFFERTA FATTA</Button>
                </Link>
              </Stack>
            );

          if (!myOffer)
            return (
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    localStorage.setItem("ad", params.row.id);
                    viewOffer();
                  }}
                >
                  FARE OFFERTA
                </Button>
              </Stack>
            );
        },
      },
    ],
    [user, userCanEdit, viewOffer]
  );

  const { dataGridProps } = useDataGrid({
    resource: "advertisements",
    metaData: {
      populate: "*",
    },
  });

  if (!user) return <h1>Caricamento...</h1>;

  return (
    <List
      resource="advertisements"
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">ELENCO ANNUNCI</Typography>,
      }}
      canCreate={userCanEdit}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        columnVisibilityModel={{
          offers: userCanEdit,
        }}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />
    </List>
  );
}

export default AdvertisementList;
