import { useGetIdentity } from "@pankod/refine-core";
import React from "react";

function SideBarTitle({ collapsed }) {
  const { data: user } = useGetIdentity();

  function convertRole(role) {
    let r = "ospite";
    switch (role) {
      case "seller":
        r = "PRODUTTORE";
        break;
      case "admin":
        r = "ADMIN";
        break;
      case "municipality":
        r = "COMUNE";
        break;
      case "buyer":
        r = "ACQUIRENTE";
        break;

      default:
        break;
    }

    return r;
  }

  if (!user) return null;

  return (
    <div
      style={{
        height: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {collapsed && (
        <img src="/logo.png" alt="Logo" style={{ width: "50px" }} />
      )}
      {!collapsed && (
        <>
          <img src="/logo.png" alt="Logo" style={{ width: "100px" }} />
        </>
      )}
      <div className="role-title">{convertRole(user.role)}</div>
    </div>
  );
}

export default SideBarTitle;
