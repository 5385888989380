import React from "react";
import { useGetIdentity } from "@pankod/refine-core";
import {
  AppBar,
  // IconButton,
  Avatar,
  Stack,
  Toolbar,
  Typography,
} from "@pankod/refine-mui";
// import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";

// import { ColorModeContext } from "contexts";

export const Header = () => {
  // const { mode, setMode } = useContext(ColorModeContext);

  const { data: user } = useGetIdentity();
  const showUserInfo = user && (user.username || user.avatar);

  // console.log({ user });

  return (
    <AppBar color="default" position="sticky" elevation={1}>
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* <IconButton
            onClick={() => {
              setMode();
            }}
          >
            {mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}
          </IconButton> */}
          {showUserInfo && (
            <Stack direction="row" gap="16px" alignItems="center">
              {user.username && (
                <Typography variant="subtitle2">{user?.username}</Typography>
              )}
              <Avatar
                src={`https://ui-avatars.com/api/?background=random&name=${user?.username?.replace(
                  " ",
                  "+"
                )}`}
                alt={user?.username}
              />
            </Stack>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
