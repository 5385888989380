import { useState, useEffect } from "react";
import { Edit, IconButton } from "@pankod/refine-mui";
import { ArrowBack } from "@mui/icons-material";
import { useForm } from "@pankod/refine-react-hook-form";
import { useOne } from "@pankod/refine-core";
import { useParams } from "react-router-dom";

import axios from "utils/axios";

import Fields from "./Fields";

export default function CompEdit() {
  const [user, setUser] = useState(null);
  const getUser = async () => {
    const { data } = await axios.get("/api/users/me");
    setUser(data);
  };
  useEffect(() => {
    getUser();
  }, []);

  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const params = useParams();
  const record = useOne({
    resource: "users",
    id: params.id,
    metaData: {
      populate: {
        registration_request: "*",
      },
    },
  });

  const Title = () => {
    return (
      <div>
        <h1>Vista Dati Utente</h1>
      </div>
    );
  };

  const BackButton = () => {
    return (
      <IconButton
        onClick={() => {
          window.history.back();
        }}
      >
        <ArrowBack />
      </IconButton>
    );
  };

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        disabled:
          user?.role?.name !== "Admin" && user?.role?.name !== "Municipality",
      }}
      footerButtons={({ defaultButtons }) => <></>}
      headerButtons={[]}
      title={<Title />}
      goBack={<BackButton />}
    >
      <Fields
        user={user}
        register={register}
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        record={record?.data?.data?.registration_request}
      />
    </Edit>
  );
}
