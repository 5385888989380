import React from "react";
import { useOne } from "@pankod/refine-core";
import { Button, Grid, Stack, Typography } from "@pankod/refine-mui";
import { useParams } from "@pankod/refine-react-router-v6";
import DownloadIcon from "@mui/icons-material/Download";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import axios from "utils/axios";
import { toast } from "react-toastify";

function ViewRegistrationRequest() {
  const params = useParams();
  const record = useOne({
    resource: "registration-requests",
    id: params.id,
    metaData: {
      populate: { municipality: { fields: ["name"] }, business_view: true },
    },
  });

  // console.log(record.data);

  if (!record.data) return null;

  const onDownload = () => {
    if (!record.data.data.business_view?.url) {
      toast.error("Non è stata caricata nessuna visura");
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `${process.env.REACT_APP_API_URL}${record.data.data.business_view?.url}`;
    link.href = `${process.env.REACT_APP_API_URL}${record.data.data.business_view?.url}`;
    link.click();
  };

  const onActivateAccount = (accept) => {
    try {
      axios.post("/api/activate-account", {
        registration_request: params.id,
        action: accept ? "accept" : "reject",
      });
      toast.success(
        `La richiesta è stata ${accept ? "accettata" : "rifiutata"} `
      );
    } catch (error) {
      toast.error("Si è verificato un errore");
    }
  };

  return (
    <Grid item xs={12} ml={4}>
      <Grid container spacing={2} style={{ padding: "30px" }}>
        <Stack direction="column">
          <Typography variant="h3">{record.data.data.company}</Typography>
          <Typography variant="h6" ml={1} mt={4}>
            <Stack direction="column" gap={1}>
              <div>
                <strong>INDIRIZZO:</strong> {record.data.data.address} -{" "}
                {record.data.data.zip_code} - {record.data.data.city}{" "}
              </div>
              <div>
                <strong>P.IVA:</strong> {record.data.data.vat}
              </div>
              <div>
                <strong>REFERENTE:</strong> {record.data.data.first_name}{" "}
                {record.data.data.last_name} ( tel. {record.data.data.phone} )
              </div>
              <div>
                <strong>EMAIL:</strong> {record.data.data.email}
              </div>
              <div>
                <strong>COMUNE DI APPARTENENZA:</strong>{" "}
                {record.data.data.municipality.name}
              </div>
            </Stack>
          </Typography>
          <Typography variant="h6" ml={1} mt={4}>
            <Button
              startIcon={<DownloadIcon />}
              onClick={onDownload}
              variant="outlined"
              color="primary"
              value="download"
            >
              SCARICA VISURA
            </Button>
          </Typography>
          <Stack mt={10} direction="column" gap={1} ml={1}>
            <Typography variant="h6" mt={4}>
              STATO DELLA RICHIESTA
            </Typography>
            <div>
              {record.data.data.status === "submitted" && (
                <Stack direction="row" alignItems="center" gap={3}>
                  <div>IN ATTESA</div>
                  <Button
                    startIcon={<ThumbUpOffAltIcon />}
                    variant="contained"
                    onClick={() => onActivateAccount(true)}
                  >
                    APPROVA
                  </Button>
                  <Button
                    startIcon={<ThumbDownOffAltIcon />}
                    variant="outlined"
                    color="primary"
                    onClick={() => onActivateAccount(false)}
                  >
                    RIFIUTA
                  </Button>
                </Stack>
              )}
              {record.data.data.status === "accepted" && <div>APPROVATA</div>}
              {record.data.data.status === "rejected" && <div>RIFIUTATA</div>}
            </div>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ViewRegistrationRequest;
