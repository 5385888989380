import { FC } from "react";
import { Chip } from "@pankod/refine-mui";
interface StatusChipProps {
  status: "accepted" | "rejected" | "pending" | undefined;
  label: string;
}

const StatusChip: FC<StatusChipProps> = ({ status, label }) => {
  let colors = {
    backgroundColor: "rgb(241,242,244)",
    color: "rgb(86, 93, 107)",
    borderColor: "rgb(229, 231, 235)",
  };

  let statusLabel = label || "in attesa";

  switch (status) {
    case "pending":
      colors = {
        backgroundColor: "rgb(251,242,222)",
        color: "rgb(130, 92, 15)",
        borderColor: "rgb(229, 231, 235)",
      };
      statusLabel = "in attesa";
      break;
    case "accepted":
      colors = {
        backgroundColor: "rgb(214,250,228)",
        color: "rgb(14, 117, 55)",
        borderColor: "rgb(229, 231, 235)",
      };
      statusLabel = "accettata";
      break;
    case "rejected":
      colors = {
        backgroundColor: "rgb(250,229,233)",
        color: "rgb(176, 35, 58)",
        borderColor: "rgb(229, 231, 235)",
      };
      statusLabel = "rifiutata";
      break;
    default:
      break;
  }

  return (
    <Chip
      sx={{ ...colors, fontSize: "12px", lineHeight: "12px", height: "20px" }}
      label={statusLabel}
      variant="outlined"
    />
  );
};

export default StatusChip;
