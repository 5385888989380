import { TextField, Grid } from "@pankod/refine-mui";

export default function CompFields({ register, errors }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          {...register("name", { required: "Nome obbligatorio" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.name}
          helperText={errors.name?.message}
          required
          fullWidth
          label="Nome"
        />
      </Grid>
    </Grid>
  );
}
