import { Create } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

import Fields from "./Fields";
import { useEffect, useState } from "react";
import axiosInstance from "utils/axios";

export default function CompCreate() {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axiosInstance.get("/api/users/me");
      setUser(data);
    };
    fetchUser();
  }, []);

  if (!user) return null;

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Fields
        register={register}
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        user={user}
      />
    </Create>
  );
}
