import { API_URL } from "./constants";
import { useEffect, useState } from "react";
import "./App.css";

// refine
import {
  ErrorComponent,
  ReadyPage,
  CssBaseline,
  createTheme,
  ThemeProvider,
  GlobalStyles,
  RefineSnackbarProvider,
  notificationProvider,
  itIT,
} from "@pankod/refine-mui";
import { useTranslation } from "react-i18next";

// refine utils
import { Refine, Authenticated } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "@pankod/refine-strapi-v4";
import { authProvider } from "authProvider";

// utils
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "utils/axios";

// custom pages and components
import { Header } from "components/layout";
import CustomLayout from "./components/CustomLayout";
import LoginPage from "./components/user/LoginPage";
import PasswordForgot from "components/user/PasswordForgot";
import PasswordReset from "components/user/PasswordReset";
import Dashboard from "pages/Dashboard";
import Settings from "pages/Settings";

import SellerRegistration from "pages/registration/UserRegistration";
import SellerRegistrationEdit from "pages/sellers/Edit";

import ViewRegistrationRequest from "components/ViewRegistrationRequest";
import ViewDecoRequest from "components/ViewDecoRequest";

// resources
import UsersList from "pages/users/List";
// import UsersCreate from "pages/users/Create";
import UsersEdit from "pages/users/Edit";

import UserCategoryList from "pages/management/user-category/List";
import UserCategoryCreate from "pages/management/user-category/Create";
import UserCategoryEdit from "pages/management/user-category/Edit";

import ProductCategoryList from "pages/management/product-category/List";
import ProductCategoryCreate from "pages/management/product-category/Create";
import ProductCategoryEdit from "pages/management/product-category/Edit";

import MunicipalityList from "pages/management/municipality/List";
import MunicipalityCreate from "pages/management/municipality/Create";

import ProductList from "pages/products/List";
import ProductCreate from "pages/products/Create";
import ProductEdit from "pages/products/Edit";

import SellerList from "pages/sellers/List";
import BuyerList from "pages/buyers/List";

import DeCoRequestCreate from "pages/decorequests/Create";

import AdvertisementList from "pages/advertisements/List";
import AdvertisementCreate from "pages/advertisements/Create";
import AdvertisementEdit from "pages/advertisements/Edit";
import AdvertisementOffersList from "pages/advertisements/ListOffers";

import OfferCreate from "pages/offers/Create";
import OfferList from "pages/offers/List";
import OfferView from "pages/offers/View";

// Icons
import { Task } from "@mui/icons-material";
import DecoRequestList from "pages/decorequests/List";
import ViewAdvertisement from "components/ViewAdvertisement";
import SideBarTitle from "Title";

export const themeOptions = {
  palette: {
    mode: "light",
    primary: {
      // main: "#8d99ae",
      main: "#a4421d",
      // contrastText: "#a4421d",
    },
    secondary: {
      main: "#fff",
      contrastText: "#a4421d",
      // main: "#2b2d42",
    },
    info: {
      main: "#a4421d",
      // main: "#edf2f4",
    },
  },
  itIT,
};

const theme = createTheme(themeOptions);
const queryClient = new QueryClient();
window.queryClient = queryClient;

const adminResources = [
  {
    name: "user-categories",
    icon: <Task />,
    options: { label: "Categorie Utente" },
    list: UserCategoryList,
    create: UserCategoryCreate,
    edit: UserCategoryEdit,
  },
  {
    name: "users",
    icon: <Task />,
    options: { label: "Utenti" },
    list: UsersList,
    // create: UsersCreate,
    edit: UsersEdit,
  },
  {
    name: "product-categories",
    icon: <Task />,
    options: { label: "Categorie Prodotto" },
    list: ProductCategoryList,
    create: ProductCategoryCreate,
    edit: ProductCategoryEdit,
  },
  {
    name: "municipalities",
    icon: <Task />,
    options: { label: "Comuni" },
    list: MunicipalityList,
    create: MunicipalityCreate,
    // edit: Edit,
  },
  {
    name: "products",
    icon: <Task />,
    options: { label: "Prodotti" },
    list: ProductList,
    create: ProductCreate,
    edit: ProductEdit,
  },
  {
    name: "produttori",
    icon: <Task />,
    options: { label: "Produttori" },
    list: SellerList,
    // create: ProductCreate,
    // edit: Edit,
  },
  {
    name: "acquirenti",
    icon: <Task />,
    options: { label: "Acquirenti" },
    list: BuyerList,
    // create: ProductCreate,
    // edit: Edit,
  },
  {
    name: "advertisements",
    icon: <Task />,
    options: { label: "Annunci" },
    list: AdvertisementList,
    create: AdvertisementCreate,
    edit: AdvertisementEdit,
  },
];

const sellerResources = [
  {
    name: "products",
    icon: <Task />,
    options: { label: "Prodotti" },
    list: ProductList,
    create: ProductCreate,
    edit: ProductEdit,
  },
  {
    name: "deco-requests",
    icon: <Task />,
    options: { label: "De.Co" },

    create: DeCoRequestCreate,
    // list: ProductList,
    // edit: ProductEdit,
  },
  {
    name: "advertisements",
    icon: <Task />,
    options: { label: "Annunci" },
    list: AdvertisementList,
    // create: AdvertisementCreate,
    // edit: AdvertisementEdit,
  },

  {
    name: "offers",
    icon: <Task />,
    options: { label: "Offerte" },
    list: OfferList,
    create: OfferCreate,
    // edit: OfferEdit,
  },
];

const buyerResources = [
  // {
  //   name: "products",
  //   icon: <Task />,
  //   options: { label: "Prodotti" },
  //   list: ProductList,
  //   create: ProductCreate,
  //   edit: ProductEdit,
  // },
  {
    name: "advertisements",
    icon: <Task />,
    options: { label: "Annunci" },
    list: AdvertisementList,
    create: AdvertisementCreate,
    edit: AdvertisementEdit,
  },
];

const municipalityResources = [
  {
    name: "produttori",
    icon: <Task />,
    options: { label: "Produttori" },
    list: SellerList,
    // create: ProductCreate,
    // edit: Edit,
  },
  {
    name: "acquirenti",
    icon: <Task />,
    options: { label: "Acquirenti" },
    list: BuyerList,
    // create: ProductCreate,
    // edit: Edit,
  },
  {
    name: "deco-requests",
    icon: <Task />,
    options: { label: "Richieste De.Co" },
    list: DecoRequestList,
    // create: ProductCreate,
    // edit: Edit,
  },
];

const App = () => {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key, options) => t(key, options),
    changeLocale: (lang) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(null);
  const [selectedResources, setSelectedResources] = useState(sellerResources);

  // useEffect(() => {
  //   console.log("lang: ", i18nProvider.getLocale());
  //   i18nProvider.changeLocale("it");
  // }, []);

  // const getUser = useCallback(() => {
  //   return localStorage.getItem("user");
  // }, []);

  useEffect(() => {
    const fetchUser = async () => {
      console.log("recupero informazioni");
      const { data } = await axios.get("/api/users/me");
      setUser(data);
      localStorage.setItem(
        "user",
        JSON.stringify({ id: data.id, role: data.role.name })
      );
      console.log(data.role.name);
      if (data.role?.name === "Authenticated") {
        setSelectedResources(adminResources);
        console.log("ADMIN");
      }
      if (data.role?.name === "Seller") {
        setSelectedResources(sellerResources);
        console.log("SELLER");
      }
      if (data.role?.name === "Buyer") {
        setSelectedResources(buyerResources);
        console.log("BUYER");
      }
      if (data.role?.name === "Municipality") {
        setSelectedResources(municipalityResources);
        console.log("MUNICIPALITY");
      }
    };
    if (!user) fetchUser();
  }, [user]);

  // useEffect(() => {
  //   const refresh = async () => {
  //     let auth = getUser();
  //     if (auth) {
  //       await authProvider.getUserIdentity();
  //       auth = localStorage.getItem("user");
  //       const parsedUser = JSON.parse(auth);
  //       console.log(parsedUser);
  //       setUser(parsedUser);
  //       if (parsedUser?.role?.name === "authenticated") {
  //         setSelectedResources(adminResources);
  //         console.log("ADMIN");
  //       }
  //       if (parsedUser?.role?.name === "Seller") {
  //         setSelectedResources(sellerResources);
  //         console.log("SELLER");
  //       }
  //       if (parsedUser?.role?.name === "Buyer") {
  //         setSelectedResources(buyerResources);
  //         console.log("BUYER");
  //       }
  //       if (parsedUser?.role?.name === "Municipality") {
  //         // setSelectedResources(municipalityResources);
  //         console.log("MUNICIPALITY");
  //       }
  //     } else {
  //       // setSelectedResources(municipalityResources);
  //       console.log("SCHOLASTIC");
  //     }
  //   };
  //   refresh();
  // }, [getUser]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <Refine
            i18nProvider={i18nProvider}
            routerProvider={{
              ...routerProvider,
              routes: [
                {
                  element: <PasswordForgot />,
                  path: "/forgot-password",
                  layout: false,
                },
                {
                  element: <SellerRegistration />,
                  path: "/registration-requests/:type",
                  layout: false,
                },
                {
                  element: <PasswordReset />,
                  path: "/reset-password",
                  layout: false,
                },
                {
                  element: <ViewRegistrationRequest />,
                  path: "/produttori/:id",
                  layout: true,
                },
                {
                  element: <ViewAdvertisement />,
                  path: "/view-advertisement/:id",
                  layout: true,
                },
                {
                  element: <ViewRegistrationRequest />,
                  path: "/acquirenti/:id",
                  layout: true,
                },
                {
                  element: <ViewDecoRequest />,
                  path: "/vedi-richieste-deco/:id",
                  layout: true,
                },
                {
                  element: <OfferView />,
                  path: "/offer/:id",
                  layout: true,
                },
                {
                  element: <SellerRegistrationEdit />,
                  path: "/seller/edit/:id",
                  layout: true,
                },
                {
                  element: <AdvertisementOffersList />,
                  path: "/adv-offers/:id",
                  layout: true,
                },
                {
                  element: (
                    <Authenticated>
                      <Settings />
                    </Authenticated>
                  ),
                  path: "/settings",
                  layout: true,
                },
              ],
            }}
            resources={selectedResources}
            authProvider={authProvider}
            dataProvider={DataProvider(API_URL + "/api", axios)}
            notificationProvider={notificationProvider}
            Title={({ collapsed }) => <SideBarTitle collapsed={collapsed} />}
            Header={Header}
            Layout={CustomLayout}
            DashboardPage={Dashboard}
            ReadyPage={ReadyPage}
            LoginPage={LoginPage}
            catchAll={<ErrorComponent />}
          />
        </QueryClientProvider>
      </RefineSnackbarProvider>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
