import { useMemo } from "react";
import {
  Typography,
  useDataGrid,
  DataGrid,
  List,
  Button,
  itIT,
  Chip,
} from "@pankod/refine-mui";
import { Stack } from "@pankod/refine-mui";
import { Link } from "@pankod/refine-react-router-v6";
import { format } from "date-fns";

function BuyerList() {
  const columns = useMemo(
    () => [
      // { field: "id", headerName: "ID" },
      {
        field: "offer_date",
        headerName: "Data",
        minWidth: 120,
        renderCell: function render(p) {
          console.log(p);
          return (
            <div>
              {format(
                p.row?.advertisement?.createdAt
                  ? new Date(p.row?.advertisement?.createdAt)
                  : new Date(),
                "dd-MM-yyyy"
              )}
            </div>
          );
        },
      },
      {
        field: "status",
        headerName: "Stato",
        minWidth: 120,
        renderCell: function render(p) {
          return (
            <Chip
              color={
                p.formattedValue == "accepted"
                  ? "success"
                  : p.formattedValue == "pending"
                  ? "info"
                  : "error"
              }
              label={
                p.formattedValue == "accepted"
                  ? "ACCETTATA"
                  : p.formattedValue == "pending"
                  ? "IN ATTESA"
                  : "RIFIUTATA"
              }
            />
          );
        },
      },
      {
        field: "seller",
        headerName: "Azienda",
        flex: 1,
        minWidth: 200,
        renderCell: function render(p) {
          return (
            <div>{p.row.advertisement?.owner?.business_name || "N/A"}</div>
          );
        },
      },
      {
        field: "title",
        headerName: "Annuncio",
        flex: 1,
        minWidth: 300,
        renderCell: function render(p) {
          return <div>{p.row?.advertisement?.title || "N/A"}</div>;
        },
      },
      {
        field: "seller_reply",
        headerName: "Risposta",
        flex: 1,
        minWidth: 300,
      },

      {
        headerName: "Azioni",
        field: "actions",
        minWidth: 50,
        sortable: false,
        renderCell: function render(p) {
          return (
            <Stack direction="row" spacing={1}>
              <Link to={`/offer/${p.row.id}`}>
                <Button>VEDI</Button>
              </Link>
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps } = useDataGrid({
    resource: "offers",
    metaData: {
      populate: {
        advertisement: {
          populate: "*",
        },
      },
    },
  });

  //   useEffect(() => {
  //     setFilters([
  //       {
  //         field: "advertisement",
  //         value: Number(localStorage.getItem("ad")),
  //         operator: "eq",
  //       },
  //     ]);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  return (
    <List
      resource="offers"
      createButtonProps={{ size: "small" }}
      canCreate={false}
      headerProps={{
        title: <Typography variant="h5">ELENCO OFFERTE</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />
    </List>
  );
}

export default BuyerList;
