import { useState, useEffect } from "react";

import { Edit } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { useOne } from "@pankod/refine-core";
import { useParams } from "react-router-dom";
import { isAfter } from "date-fns";

import Fields from "./Fields";
import axiosInstance from "utils/axios";

export default function CompEdit() {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axiosInstance.get("/api/users/me");
      setUser(data);
    };
    fetchUser();
  }, []);

  const params = useParams();
  const record = useOne({
    resource: "advertisements",
    id: params.id,
    metaData: {
      populate: "*",
    },
  });

  // console.log(record?.data?.data);

  if (!record?.data || !user) return null;

  const exp_date = record.data.data.expiry_date;
  const today = new Date();

  // console.log({ exp_date });
  var is_after = isAfter(today, new Date(exp_date));
  // console.log({ is_after: is_after });

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      footerButtons={is_after ? <></> : undefined}
      headerButtons={<></>}
    >
      <Fields
        register={register}
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        record={record?.data?.data}
        user={user}
      />
    </Edit>
  );
}
