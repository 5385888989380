import { Grid } from "@pankod/refine-mui";

const imgStyle = {
  height: "100%",
  width: "100%",
  objectFit: "contain",
  objectPosition: "center",
};

export default function ({ height, sx, sm }) {
  return (
    <Grid container spacing={2} height={height ?? "100%"} sx={{ ...sx }}>
      <Grid item xs={6} sm={sm ?? 3}>
        <img src="/loghi_2.jpeg" alt="logo2" style={imgStyle} />
      </Grid>
      <Grid item xs={6} sm={sm ?? 3}>
        <img src="/loghi_3.jpeg" alt="logo3" style={imgStyle} />
      </Grid>
      <Grid item xs={6} sm={sm ?? 3}>
        <img src="/loghi_4.jpeg" alt="logo4" style={imgStyle} />
      </Grid>
      <Grid item xs={6} sm={sm ?? 3}>
        <img src="/loghi_5.jpeg" alt="logo5" style={imgStyle} />
      </Grid>
    </Grid>
  );
}
