import React, { useEffect, useMemo, useState } from "react";
import { useInvalidate, useOne } from "@pankod/refine-core";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@pankod/refine-mui";
import { useParams } from "@pankod/refine-react-router-v6";
import axios from "utils/axios";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import DownloadIcon from "@mui/icons-material/Download";
import { toast } from "react-toastify";

function ViewDecoRequest() {
  const [firstLoad, setFirstLoad] = useState(false);
  const [premiseOK, setPremiseOK] = useState(false);
  const [historyOK, setHistoryOK] = useState(false);
  const [descriptionOK, setDescriptionOK] = useState(false);
  const [productionAreaOK, setProductionAreaOK] = useState(false);
  const [productionMethodOK, setProductionMethodOK] = useState(false);
  const [propertiesOK, setPropertiesOK] = useState(false);
  const [commercializationOK, setCommercializationOK] = useState(false);
  const [filesOK, setFilesOK] = useState([]);

  const [errors, setError] = useState("");
  const [user, setUser] = useState(null);

  const userCanEdit =
    user?.role?.name === "Authenticated" || user?.role?.name === "Municipality";

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axios.get("/api/users/me");
      setUser(data);
    };
    fetchUser();
  }, []);

  const invalidate = useInvalidate();

  const params = useParams();
  const record = useOne({
    resource: "deco-requests",
    id: params.id,
    metaData: {
      populate: {
        files: true,
        product: {
          populate: { product_category: true, owner: true },
        },
      },
    },
  });

  useEffect(() => {
    if (record.data && !firstLoad) {
      setFirstLoad(true);
      //   setFilesOK(
      //     Array.apply(null, { length: record.data?.data.files.length }).map(
      //       () => false
      //     )
      //   );
      let filesAreOk = [];

      if (record.data?.data.files) {
        console.log("qui");
        filesAreOk = record.data?.data.files.map((file) => {
          const found = record.data.data.files_ok?.find(
            (r) => r.id && r.accepted
          );
          return found ? true : false;
        });
      }

      console.log({ filesAreOk });
      setFilesOK(filesAreOk);
      setPremiseOK(record.data?.data.premise_ok || false);

      console.log({ history: record });
      setHistoryOK(record.data?.data.history_ok || false);
      setDescriptionOK(record.data?.data.description_ok || false);
      setProductionAreaOK(record.data?.data.production_area_ok || false);
      setProductionMethodOK(record.data?.data.production_method_ok || false);
      setPropertiesOK(record.data?.data.properties_ok || false);
      setCommercializationOK(record.data?.data.commercialization_ok || false);
      setError(record.data?.data.response || "");
    }
  }, [firstLoad, record]);

  const onDownload = (url) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `${
      process.env.REACT_APP_API_URL || "https://api.amerinotipico.it"
    }${url}`;
    link.href = `${
      process.env.REACT_APP_API_URL || "https://api.amerinotipico.it"
    }${url}`;
    link.click();
  };

  function createData(name, id, status, cb) {
    return { name, id, status, cb };
  }
  const decoStatus =
    (record && record.data && record.data.data.status) || "pending";

  const rows = useMemo(
    () => [
      createData("PREMESSA", "premise", premiseOK, () => {
        if (decoStatus === "pending") setPremiseOK((val) => !val);
      }),
      createData("CENNI E RICERCA STORICA", "history", historyOK, () => {
        if (decoStatus === "pending") setHistoryOK((val) => !val);
      }),
      createData(
        "a) descrizione generale",
        "description",
        descriptionOK,
        () => {
          if (decoStatus === "pending") setDescriptionOK((val) => !val);
        }
      ),
      createData(
        "b) area di produzione",
        "production_area",
        productionAreaOK,
        () => {
          if (decoStatus === "pending") setProductionAreaOK((val) => !val);
        }
      ),
      createData(
        "c) metodo di coltivazione / produzione / preparazione (compresi ingredienti e quantità)",
        "production_method",
        productionMethodOK,
        () => {
          if (decoStatus === "pending") setProductionMethodOK((val) => !val);
        }
      ),
      createData(
        "d) impieghi / proprietà e caratteristiche note del prodotto",
        "properties",
        propertiesOK,
        () => {
          if (decoStatus === "pending") setPropertiesOK((val) => !val);
        }
      ),
      createData(
        "e) consumo / commercializzazione del prodotto",
        "commercialization",
        commercializationOK,
        () => {
          if (decoStatus === "pending") setCommercializationOK((val) => !val);
        }
      ),
    ],
    [
      decoStatus,
      commercializationOK,
      descriptionOK,
      historyOK,
      premiseOK,
      productionAreaOK,
      productionMethodOK,
      propertiesOK,
    ]
  );

  const checkData = () => {
    let isOk = true;
    rows.forEach((row) => {
      // console.log(row.status, row.name);
      if (row.status === false) isOk = false;
    });
    filesOK.forEach((file) => {
      if (file === false) isOk = false;
    });

    return isOk;
  };

  // useEffect(() => {
  //   setError((old) => {
  //     console.log({ old });
  //     let tmp = old || "";

  //     rows.forEach((row) => {
  //       if (row.status === true) {
  //         // console.log(row.name, old);
  //         tmp = tmp.replace(row.name, "");
  //       } else {
  //         if (!tmp.includes(row.name)) {
  //           tmp += `\n${row.name}`;
  //         }
  //       }
  //     });

  //     console.log(tmp);
  //     return tmp;
  //   });
  // }, [rows]);

  const toggleFile = (i) => {
    if (!userCanEdit) return;
    console.log(i);
    const newFiles = [...filesOK];
    newFiles[i] = !filesOK[i];
    setFilesOK([...newFiles]);
  };

  const sendDecoResponse = async (accept) => {
    const oldData = { ...record.data?.data };

    const responseForFiles = oldData.files
      ? oldData.files.map((file, idx) => {
          return {
            id: file.id,
            accepted: filesOK[idx],
          };
        })
      : true;
    console.log({ responseForFiles });
    const dataToSend = {
      ...oldData,
      premise_ok: premiseOK,
      history_ok: historyOK,
      description_ok: descriptionOK,
      production_area_ok: productionAreaOK,
      production_method_ok: productionMethodOK,
      properties_ok: propertiesOK,
      commercialization_ok: commercializationOK,
      files_ok: responseForFiles,
      status: accept ? "accepted" : "rejected",
      response: errors,
    };

    console.log({ dataToSend });

    try {
      const resp = await axios.put(`/api/deco-requests/${params.id}`, {
        data: dataToSend,
      });
      console.log(resp);
      toast.success(
        `La richiesta è stata ${accept ? "accettata" : "rifiutata"} `
      );
      invalidate({
        resource: "deco-requests",
        invalidates: ["detail"],
        id: params.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (!record.data) return null;
  return (
    <>
      <Grid item xs={24} ml={4}>
        <Grid container spacing={2} style={{ padding: "30px" }}>
          <Stack direction="column">
            <Typography variant="h4">DETTAGLIO RICHIESTA DE.CO</Typography>
            <Stack direction="row" alignItems="flex-end" gap={2} marginTop={2}>
              <Typography variant="h5">
                {record.data.data.product.name}
              </Typography>
              <Typography variant="body1" paddingBottom={"4px"}>
                {record.data.data.product.product_category.name}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <h3>DICHIARAZIONE:</h3>
        <Grid id="deco-visible" container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Il sottoscritto"
              hidden
              disabled
              type="text"
              value={record.data.data.full_name}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="nato il"
              hidden
              disabled
              type="text"
              value={record.data.data.date_of_birth}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="nato a"
              hidden
              disabled
              type="text"
              value={record.data.data.birthplace}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="e residente in"
              hidden
              disabled
              type="text"
              value={record.data.data.city}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Via"
              hidden
              disabled
              type="text"
              value={record.data.data.address1}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Codice Fiscale"
              hidden
              disabled
              type="text"
              value={record.data.data.fiscal_code}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="nella qualità di titolare/leg. rappr. della Ditta"
              hidden
              disabled
              type="text"
              value={record.data.data.business_name}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            &nbsp;
          </Grid>

          <Grid item xs={6}>
            <h4>Avente qualifica di</h4>
          </Grid>

          <Grid item xs={6}>
            &nbsp;
          </Grid>

          <Grid item xs={12} sx={{ marginTop: "-20px" }}>
            <FormControlLabel
              label="Imprenditore agricolo"
              control={
                <Checkbox
                  disabled
                  checked={record.data.data.is_imprenditore_agricolo}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "-20px" }}>
            <FormControlLabel
              label="Artigiano"
              control={
                <Checkbox disabled checked={record.data.data.is_artigiano} />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "-20px" }}>
            <FormControlLabel
              label="Commerciante al dettaglio"
              control={
                <Checkbox disabled checked={record.data.data.is_commerciante} />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "-20px" }}>
            <FormControlLabel
              label="esercente attività di somministrazione di alimenti e bevande"
              control={
                <Checkbox disabled checked={record.data.data.is_esercente} />
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            alignItems="center"
            display="flex"
            sx={{ marginTop: "-20px", marginBottom: "20px" }}
          >
            <FormControlLabel
              label="Altro"
              control={
                <Checkbox disabled checked={record.data.data.is_other} />
              }
            />
            <TextField
              type="text"
              variant="outlined"
              sx={{ padding: "0x 8px" }}
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Con sede in"
              hidden
              disabled
              type="text"
              value={record.data.data.sede_city}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="via"
              hidden
              disabled
              type="text"
              value={record.data.data.sede_address}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Codice Fiscale"
              hidden
              disabled
              type="text"
              value={record.data.data.sede_fiscal_code}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            &nbsp;
          </Grid>

          <Grid item xs={12}>
            <h4>Premesso che la propria ditta produce</h4>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Codice Fiscale"
              hidden
              disabled
              type="text"
              value={record.data.data.product.name}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            &nbsp;
          </Grid>

          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <FormControlLabel
              label="il suddetto prodotto è già iscritto nel registro comunale De.Co., oppure"
              control={
                <Checkbox disabled checked={record.data.data.ask_check_1} />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "-20px" }}>
            <FormControlLabel
              label="per le sue prerogative e il suo stretto legame con il territorio, è meritevole di ottenere la De.Co"
              control={
                <Checkbox disabled checked={record.data.data.ask_check_2} />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <h3>CHIEDE</h3>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: "-20px" }}>
            <FormControlLabel
              label="di essere iscritto nel registro comunale De.Co. per il suddetto prodotto, oppure"
              control={
                <Checkbox
                  disabled
                  checked={record.data.data.is_already_registered}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "-20px" }}>
            <FormControlLabel
              label="lʼiscrizione nel registro De.Co. del suddetto prodotto e del proprio nominativo con possibilità di utilizzare il relativo logo"
              control={
                <Checkbox
                  disabled
                  checked={record.data.data.is_deserving_deco}
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <h3>A tal fine, dichiara, sotto la propria responsabilità:</h3>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: "-20px" }}>
            <FormControlLabel
              label="di impegnarsi a rispettare, in caso di attribuzione della De.Co., le disposizioni dello specifico regolamento comunale, il disciplinare di produzione
              o le indicazioni contenute nella scheda descrittiva del prodotto, approvati dal Comune"
              control={
                <Checkbox
                  disabled
                  checked={record.data.data.responsibility_check_1}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "-20px" }}>
            <FormControlLabel
              label="che nella produzione, trasformazione, preparazione, commercializzazione ed etichettatura del prodotto sono rispettate tutte le norme vigenti"
              control={
                <Checkbox
                  disabled
                  checked={record.data.data.responsibility_check_2}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "-20px" }}>
            <FormControlLabel
              label="che non sono impiegati prodotti transgenici, ovvero contenenti organismi geneticamente modificati (O.G.M.)"
              control={
                <Checkbox
                  disabled
                  checked={record.data.data.responsibility_check_3}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "-20px" }}>
            <FormControlLabel
              label="di accettare i controlli che potranno essere disposti alla sua azienda in ordine alle corrette modalità di preparazione del prodotto De.C.O."
              control={
                <Checkbox
                  disabled
                  checked={record.data.data.responsibility_check_4}
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <h3>SCHEDA DESCRITTIVA DEL PRODOTTO</h3>
          </Grid>

          <TableContainer component={Paper} sx={{ marginTop: "40px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      border: "1px solid gray",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant="h6" paddingBottom={"4px"}>
                        {row.name}
                      </Typography>
                      <Typography variant="body1" paddingBottom={"4px"}>
                        {record.data?.data[row.id]}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderLeft: "1px solid gray",
                        width: "120px",
                      }}
                    >
                      {userCanEdit ? (
                        <Stack direction="row" alignItems="center" gap={1}>
                          <HighlightOffIcon
                            onClick={() => {
                              if (userCanEdit) row.cb();
                            }}
                            fontSize="large"
                            sx={{
                              color: `${
                                row.status === false ? "red" : "lightgray"
                              }`,
                            }}
                          />
                          <CheckCircleOutlineIcon
                            onClick={() => {
                              if (userCanEdit) row.cb();
                            }}
                            fontSize="large"
                            sx={{
                              color: `${
                                row.status === true ? "green" : "lightgray"
                              }`,
                            }}
                          />
                        </Stack>
                      ) : (
                        <>
                          {record.data?.data.status === "pending" ? (
                            <>
                              <PendingActionsIcon
                                fontSize="large"
                                sx={{
                                  color: "lightgray",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              {row.status === true ? (
                                <CheckCircleOutlineIcon
                                  fontSize="large"
                                  sx={{
                                    color: "green",
                                  }}
                                />
                              ) : (
                                <HighlightOffIcon
                                  fontSize="large"
                                  sx={{
                                    color: "red",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="column" gap={1} marginTop={4} width="100%">
            <Typography variant="h6" paddingBottom={"4px"}>
              ALLEGATI
            </Typography>
            <TableContainer component={Paper} sx={{ marginTop: "40px" }}>
              <Table
                sx={{ minWidth: 650, width: "100%" }}
                aria-label="simple table"
              >
                <TableBody>
                  {record.data?.data?.files?.map((doc, index) => {
                    return (
                      <TableRow
                        key={doc.id}
                        sx={{
                          border: "1px solid gray",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Stack gap={2} direction="row" alignItems="center">
                            {doc.mime.includes("image") ? (
                              <ImageIcon />
                            ) : (
                              <DescriptionIcon />
                            )}
                            <Typography variant="body1">{doc.name}</Typography>
                            <Button
                              startIcon={<DownloadIcon />}
                              onClick={() => onDownload(doc.url)}
                              variant="outlined"
                              color="primary"
                              value="download"
                            >
                              SCARICA
                            </Button>
                          </Stack>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            borderLeft: "1px solid gray",
                            width: "120px",
                          }}
                        >
                          {userCanEdit ? (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <HighlightOffIcon
                                onClick={() => toggleFile(index)}
                                fontSize="large"
                                sx={{
                                  color: `${
                                    filesOK[index] === false
                                      ? "red"
                                      : "lightgray"
                                  }`,
                                }}
                              />
                              <CheckCircleOutlineIcon
                                onClick={() => toggleFile(index)}
                                fontSize="large"
                                sx={{
                                  color: `${
                                    filesOK[index] === true
                                      ? "green"
                                      : "lightgray"
                                  }`,
                                }}
                              />
                            </Stack>
                          ) : (
                            <>
                              {record.data?.data.status === "pending" ? (
                                <>
                                  <PendingActionsIcon
                                    fontSize="large"
                                    sx={{
                                      color: "lightgray",
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  {filesOK[index] === true ? (
                                    <CheckCircleOutlineIcon
                                      fontSize="large"
                                      sx={{
                                        color: "green",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffIcon
                                      fontSize="large"
                                      sx={{
                                        color: "red",
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>
        <Grid container spacing={2} style={{ padding: "30px" }}>
          <Stack direction="column" gap={1} marginTop={4} width="100%">
            <Typography variant="h6" paddingBottom={"4px"}>
              MOTIVAZIONE
            </Typography>
            {userCanEdit ? (
              <TextField
                sx={{ width: "100%", minWidth: 650 }}
                multiline
                rows={10}
                value={errors}
                onChange={(e) => setError(e.target.value)}
              />
            ) : (
              <Typography variant="overline">
                <pre
                  style={{
                    lineHeight: "1rem",
                    marginTop: "0",
                    marginBottom: "40px",
                  }}
                >
                  {errors}
                </pre>
              </Typography>
            )}
          </Stack>
        </Grid>
        {userCanEdit && record.data?.data.status === "pending" && (
          <Stack
            sx={{ padding: "30px" }}
            direction="row"
            gap={4}
            marginTop={2}
            marginBottom={16}
            width="100%"
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => sendDecoResponse(false)}
            >
              RIFIUTA
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!checkData()}
              onClick={() => sendDecoResponse(true)}
            >
              ACCETTA
            </Button>
          </Stack>
        )}
      </Grid>
    </>
  );
}

export default ViewDecoRequest;
