import {
  DataGrid,
  List,
  Stack,
  Typography,
  DeleteButton,
  useDataGrid,
  itIT,
} from "@pankod/refine-mui";
import { Link } from "@pankod/refine-react-router-v6";
import { useEffect, useMemo } from "react";

import { useGetIdentity } from "@pankod/refine-core";

function SellerList() {
  const { data: user } = useGetIdentity();

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID" },
      {
        field: "company",
        headerName: "Nome Azienda",
        flex: 1,
        minWidth: 300,
      },
      {
        field: "municipality",
        headerName: "Comune di appartenenza",
        flex: 1,
        minWidth: 100,
        maxWidth: 300,
        renderCell: function render(p) {
          return <span>{p.row.municipality.name}</span>;
        },
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 300,
      },
      {
        field: "status",
        headerName: "status",
        flex: 1,
        minWidth: 100,
        maxWidth: 300,
        renderCell: function render(p) {
          return (
            <span>
              {p.row.status === "submitted"
                ? "Da approvare"
                : p.row.status === "accepted"
                ? "Approvato"
                : "Non approvato"}
            </span>
          );
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(p) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Link to={`/produttori/${p.row.id}`}>VEDI</Link>
              {user?.role == "authenticated" && (
                <DeleteButton
                  hideText
                  resourceNameOrRouteName="registration-requests"
                  recordItemId={p.row.id}
                />
              )}
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, setFilters } = useDataGrid({
    resource: "registration-requests",
    metaData: {
      populate: { municipality: { fields: ["name"] } },
    },
  });

  useEffect(() => {
    setFilters([
      {
        field: "is_seller",
        value: true,
        operator: "eq",
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List
      resource="registration-requests"
      createButtonProps={{ size: "small" }}
      canCreate={false}
      headerProps={{
        title: <Typography variant="h5">ELENCO PRODUTTORI</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />
    </List>
  );
}

export default SellerList;
