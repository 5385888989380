import React, { useEffect, useState } from "react";
import { useGetIdentity, useOne } from "@pankod/refine-core";
import {
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@pankod/refine-mui";
import { useParams } from "@pankod/refine-react-router-v6";
import { DataGrid, itIT } from "@mui/x-data-grid";

// Icons
import DownloadIcon from "@mui/icons-material/Download";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import Back from "@mui/icons-material/ChevronLeftSharp";
import axios from "utils/axios";
import { toast } from "react-toastify";

const columns = [
  { field: "col1", headerName: "PRODOTTO", flex: 1, minWidth: 200 },
  { field: "col2", headerName: "CATEGORIA", flex: 1, minWidth: 200 },
  { field: "col3", headerName: "QUANTITA'", flex: 1, minWidth: 100 },
  { field: "col4", headerName: "PREZZO TOTALE", flex: 1, minWidth: 100 },
];

function ViewOffer() {
  const params = useParams();
  const record = useOne({
    resource: "offers",
    id: params.id,
    metaData: {
      populate: "*",
    },
  });

  const [buyerResponse, setBuyerResponse] = useState(
    record?.data?.data.buyer_reply
  );

  const [replySent, setReplysent] = useState(!!record?.data?.data.buyer_reply);

  const { data: user } = useGetIdentity();

  const isBuyer = user && user.role === "buyer";

  useEffect(() => {
    if (record?.data?.data.buyer_reply) {
      setReplysent(true);
      setBuyerResponse(record?.data.data.buyer_reply);
    } else {
      setReplysent(false);
    }
  }, [record]);

  if (!record.data) return null;

  const onDownload = (url) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `${process.env.REACT_APP_API_URL}${url}`;
    link.href = `${process.env.REACT_APP_API_URL}${url}`;
    link.click();
  };

  const adv = record.data.data.advertisement;
  const doc = record.data.data.file;
  const status = record.data.data.status;

  const products = record.data.data.products || [];
  const rows = products.map((p, i) => {
    return {
      id: p.name + "-" + i,
      col1: p.label,
      col2: p.category,
      col3: `${p?.quantity} confezioni da ${p?.unit_size} ${p?.unit_type}`,
      col4: `€ ${Number(p.price).toFixed(2)}`,
    };
  });

  const handleResponse = async () => {
    if (buyerResponse.trim() === "") return;
    try {
      await axios.put(`/api/offers/${params.id}`, {
        data: {
          ...record.data.data,
          buyer_reply: buyerResponse,
        },
      });
      toast.success("Risposta con successo");
      setReplysent(true);
    } catch (error) {
      toast.error("Risposta non inviata");
    }
  };

  const handleStatus = async (status) => {
    try {
      await axios.put(`/api/offers/${params.id}`, {
        data: {
          ...record.data.data,
          status,
        },
      });
      toast.success(
        `Offerta ${status === "accepted" ? "ACCETTATA" : "RIFIUTATA"}`
      );
      await new Promise((resolve) => setTimeout(resolve, 2000));
      window.history.back();
    } catch (error) {
      toast.error("Si è verificato un errore");
    }
  };

  return (
    <Grid item xs={12} ml={4}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button
            startIcon={<Back />}
            onClick={() => {
              window.history.back();
            }}
          >
            Indietro
          </Button>
        </Grid>
        <Grid item>
          <Chip
            color={
              record.data?.data.status === "accepted"
                ? "success"
                : record.data?.data.status === "pending"
                ? "info"
                : "error"
            }
            label={
              record.data?.data.status === "accepted"
                ? "OFFERTA ACCETTATA"
                : record.data?.data.status === "pending"
                ? "OFFERTA IN ATTESA"
                : "OFFERTA RIFIUTATA"
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ padding: "30px" }}>
        <Stack direction="column">
          <Typography variant="h3">{adv.title}</Typography>
          <Typography variant="h6">{adv.owner.business_name}</Typography>
          <hr />
          <Typography variant="body1">
            <span
              dangerouslySetInnerHTML={{
                __html: adv.description.replace(/\n/g, "<br />"),
              }}
            />
          </Typography>
        </Stack>
      </Grid>
      <Grid container spacing={2} style={{ padding: "30px" }}>
        <Typography variant="body1" ml={1} mt={4}>
          <Stack direction="row" gap={8}>
            <Stack direction="column" gap={1}>
              <div>
                <strong>Data pubblicazione:</strong>
              </div>
              <div>{adv.publication_date}</div>
            </Stack>
            <Stack direction="column" gap={1}>
              <div>
                <strong>Data scadenza:</strong>
              </div>
              <div>{adv.expiry_date}</div>
            </Stack>
            <Stack direction="column" gap={1}>
              <div>
                <strong>Comune:</strong>
              </div>
              <div>{adv.municipality?.name || "MANCA DATO"}</div>
            </Stack>
          </Stack>
        </Typography>
      </Grid>
      <Grid container spacing={2} style={{ padding: "0 30px" }}>
        <Typography variant="h6" ml={1} mt={8}>
          OFFERTA
        </Typography>
        <Box
          sx={{ border: "1px solid #888" }}
          width="100%"
          p={2}
          borderRadius={2}
          mt={2}
          minHeight={200}
        >
          {record.data.data.seller_reply}
        </Box>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ padding: "30px" }}
        direction={"column"}
      >
        <Typography variant="h6" ml={1} mt={8}>
          PRODOTTI
        </Typography>
        <div style={{ marginTop: "20px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#f5f5f5",
              },

              "& .MuiDataGrid-cell": {
                borderRight: "0x",
              },

              "& .MuiDataGrid-columnsContainer": {
                borderBottom: "0px",
              },

              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
            }}
          />
        </div>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ padding: "0 30px" }}
        direction={"column"}
      >
        <Typography variant="h6" ml={1} mt={8}>
          ALLEGATI
        </Typography>
        <div>
          {doc ? (
            <div
              key={doc.id}
              sx={{
                border: "1px solid gray",
              }}
            >
              <Stack gap={2} direction="row" alignItems="center">
                {record.data.data.file &&
                record.data.data.file.mime.includes("image") ? (
                  <ImageIcon />
                ) : (
                  <DescriptionIcon />
                )}
                <Typography variant="body1">{doc.name}</Typography>
                <Button
                  startIcon={<DownloadIcon />}
                  onClick={() => onDownload(doc.url)}
                  variant="outlined"
                  color="primary"
                  value="download"
                >
                  SCARICA
                </Button>
              </Stack>
            </div>
          ) : (
            <div>nessun allegato</div>
          )}
        </div>
      </Grid>

      <Grid container spacing={2} style={{ padding: "0 30px" }}>
        <Typography variant="h6" ml={1} mt={8}>
          RISPOSTA
        </Typography>
        <Box
          sx={{ border: "1px solid #888" }}
          width="100%"
          p={2}
          borderRadius={2}
          mt={2}
          minHeight={200}
        >
          {isBuyer ? (
            <>
              {replySent ? (
                <>{buyerResponse}</>
              ) : (
                <>
                  <TextField
                    fullWidth
                    rows={4}
                    multiline={true}
                    value={buyerResponse}
                    onChange={(e) => setBuyerResponse(e.target.value)}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "12px 0",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleResponse()}
                    >
                      Rispondi
                    </Button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {record.data.data.buyer_reply || (
                <span style={{ color: "#adadad" }}>In attesa di risposta</span>
              )}
            </>
          )}
        </Box>
        {isBuyer && status === "pending" && (
          <Stack
            sx={{ padding: "30px" }}
            direction="row"
            gap={4}
            marginTop={2}
            marginBottom={16}
            width="100%"
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleStatus("rejected")}
            >
              RIFIUTA
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleStatus("accepted")}
            >
              ACCETTA
            </Button>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}

export default ViewOffer;
