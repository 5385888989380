import { AuthHelper } from "@pankod/refine-strapi-v4";

import { TOKEN_KEY, API_URL } from "./constants";

import axios from "utils/axios";

const strapiAuthHelper = AuthHelper(API_URL + "/api");

export const authProvider = {
  login: async ({ username, password }) => {
    try {
      const { data, status } = await strapiAuthHelper.login(username, password);
      console.log("LOGIN", data, status);
      if (status === 200) {
        localStorage.setItem(TOKEN_KEY, data.jwt);

        // set header axios instance
        axios.defaults.headers.common = {
          Authorization: `Bearer ${data.jwt}`,
        };

        return Promise.resolve();
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject();
    }
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    const { data, status } = await strapiAuthHelper.me(token);
    if (status === 200) {
      const { id, username, email, role } = data;
      return Promise.resolve({
        id,
        username,
        email,
        role: role.type,
      });
    }

    return Promise.reject();
  },
};
